import React from "react"
import Layout from "../components/layout"

const NotFound = () => {
  return (
    <Layout title="404: Page not found">
      <div>
        <h1>404</h1>
      </div>
    </Layout>
  )
}
export default NotFound
